import useEvent from "../../../_hook/useEvent";
import { sessionContext } from "../../../_hook/useSession";
import { AddEventParams, EventCompletedInformation, EventState, UpdateEventParams } from "../../../_types/eventTypes";
import DEFAULT_BANNER from "../../../assets/images/dafault-banner.png";
import accessTypeArray from "../../../constants/accessType";
import { urlOrEmpty } from "../../../constants/regex";
import getNewObject from "../../../helpers/getNewObject";
import { encodeImageFileAsURL } from "../../../helpers/Tools";
import DangerButton from "../../generic/DangerButton";
import SuccessButton from "../../generic/SuccessButton";
import YesNoModal, { Sizes } from "../../generic/YesNoModal";
import SelectField from "../SelectField";
import "./index.scss";
import { IconButton, MenuItem, Select, Switch, TextField } from "@material-ui/core";
import { ArrowBack } from "@material-ui/icons";
import HelpIcon from "@material-ui/icons/Help";
import { Autocomplete as AutocompleteLab } from "@material-ui/lab";
import { Autocomplete, AutocompleteRenderInputParams } from "@mui/material";
import { noop } from "lodash";
import capitalize from "lodash/capitalize";
import isEmpty from "lodash/isEmpty";
import isArray from "lodash/isArray";
import React from "react";
import { useTranslation } from "react-i18next";
import ImageUploader from "react-images-upload";
import { createChannel as createChannelApi, deleteChannel as deleteChannelApi } from "../../../_api/channelApi";
import { ILocalisation } from "../../../_types/generalTypes";
import GoogleMaps from "../GoogleMaps";

const CLASSNAME = 'eventForm';

interface EventFormProps {
  eventState: EventState;
  pageIsLoading: boolean;
  isEdit: boolean;
  onClickBackButton?: () => void;
}

const EventForm: React.FC<EventFormProps> = ({
  eventState,
  pageIsLoading,
  isEdit,
  onClickBackButton = () => {
    noop();
  },
}) => {
  const { t } = useTranslation(['event', 'layout', 'words']);

  // Modal
  const [isHelpOpen, setIsHelpOpen] = React.useState(false);
  const [isConfirmationOpen, setIsConfirmationOpen] = React.useState(false);
  const [modalAction, setModalAction] = React.useState<'back' | 'cancel' | 'delete'>('back');
  const [isChannelDeleteConfirmationOpen, setIsChannelDeleteConfirmationOpen] = React.useState(false);

  // useSession
  const {
    sessionState,
    setDictionaries,
    setEvent,
    setPageIsLoading,
    setMessage,
    setError,
  } = React.useContext(sessionContext);
  const { dictionaries, selectedGroup, selectedCommunity, selectedEvent } =
    sessionState;
  const selectedCommunityOrGroupNuid = selectedGroup?.nuid || selectedCommunity?.nuid;
  const canDelete = selectedCommunity.parameters?.admin || selectedGroup.parameters?.admin;

  // useEvent
  const { addEvent, updateEvent, deleteEvent } = useEvent();

  // Form state
  const initialState: EventCompletedInformation = {
    accessType: null,
    category: null,
    coverPicture: null,
    creationDate: '',
    currency: 'euro',
    description: null,
    endDate: '',
    localisation: {
      address1: null,
      address2: null,
      countryCode: null,
      googleUrl: null,
      latitude: 0,
      longitude: 0,
      postalCode: null,
      town: null,
      country: null,
    },
    maxAttendees: 1,
    name: null,
    nuid: null,
    price: null,
    promote: false,
    registeredParticipants: 0,
    skills: null,
    startDate: '',
    status: 'draft',
    totalMembers: 1,
    urlTicketing: null,
    urlVisio: null,
    urlEvent: null,
    variablePrice: false,
    hasDiscussionGroup: false,
  };
  const [states, setStates] = React.useState<EventCompletedInformation>(initialState);
  const [holdStates, setHoldStates] = React.useState<EventCompletedInformation | null>(null);
  const errors = {
    isEndDateError: states.endDate < states.startDate,
    isStartDateError: states.startDate > states.endDate,
    isNameError: states.name?.trim().length === 0 || isEmpty(states.name),
    isMaxAttendeesError: states.maxAttendees <= 0 || states.maxAttendees < states.totalMembers,
    isUrlVisioError: states.urlVisio && !urlOrEmpty.test(states.urlVisio),
    isUrlTicketingError: states.urlTicketing && !urlOrEmpty.test(states.urlTicketing),
    isUrlEventError: states.urlEvent && !urlOrEmpty.test(states.urlEvent),
  };
  const isError = Object.values(errors).find((error) => error === true) !== undefined;

  const isDisabled = () => {
    const ids: string[] = [
      'accessType',
      'category',
      'coverPicture',
      'description',
      'endDate',
      'maxAttendees',
      'name',
      'price',
      'skills',
      'startDate',
    ];
    const flag: any[] = [];
    ids.forEach((id) => {
      if (id === 'localisation') {
        if (!states.localisation?.latitude) {
          flag.push(id);
        }
      } else {
        // @ts-ignore
        if (typeof states[id] === 'number') {
          if (
            // @ts-ignore
            states[id] === null ||
            // @ts-ignore
            states[id] === undefined
          ) {
            flag.push(id);
          }
        }
        // @ts-ignore
        else if (
          // @ts-ignore
          isEmpty(states[id])
        ) {
          flag.push(id);
          // @ts-ignore
        } else if (typeof states[id] === 'string') {
          if (
            // @ts-ignore
            states[id].trim().length === 0 ||
            // @ts-ignore
            states[id] === null ||
            // @ts-ignore
            states[id] === undefined
          ) {
            flag.push(id);
          }
        }
      }
    });

    if (isError) {
      return true;
    } else {
      return flag.length !== 0;
    }
  };

  // text
  const onChangeText = (event: any) => {
    const result = event.target.value === '' ? null : event.target.value;
    const prefix = 'https://';
    if (
      (event.target.id === 'urlTicketing' || event.target.id === 'urlVisio' || event.target.id === 'urlEvent') &&
      event.target.value === prefix
    ) {
      setStates({ ...states, [event.target.id]: '' });
    } else if (
      (event.target.id === 'urlTicketing' || event.target.id === 'urlVisio' || event.target.id === 'urlEvent') &&
      event.target.value.length !== 0 &&
      event.target.value.substring(0, prefix.length) !== prefix
    ) {
      setStates({ ...states, [event.target.id]: prefix + result });
    } else if (event.target.id === 'startDate' && isEmpty(states.endDate)) {
      setStates({ ...states, startDate: result, endDate: result });

    }  else {
      setStates({ ...states, [event.target.id]: result });
    }
  };

  // number
  const onChangeNumber = (event: any) => {
    setStates({ ...states, [event.target.id]: event.target.value });
  };

  // selection
  const onSelectAccessType = (newValue: any, field: any) => {
    if (newValue.key === 'private' || newValue.key === 'private_hidden') {
      setStates({...states, promote: false, [field]: newValue.key});
    } else {
      setStates({...states, [field]: newValue.key});
    }
  };

  const handleChangeCurrency = (event: any) => {
    setStates({ ...states, currency: event.target.value as string });
  };

  // switch
  const onSwitch = (event: any) => {
    setStates({ ...states, [event.target.id]: event.target.checked });
  };

  // banner
  const [coverPicture, setCoverPicture] = React.useState(null);

  // uploader
  const uploader = React.useMemo(() => {
    const handleChangePicture = async (picture: any) => {
      setStates({ ...states, coverPicture: picture });
      setCoverPicture(await encodeImageFileAsURL(picture[0]));
    };
    return (
      // @ts-ignore
      <ImageUploader
        maxFileSize={10000000}
        imgExtension={['.jpeg', '.jpg', '.png']}
        singleImage
        withIcon={false}
        buttonText={capitalize(t('layout:selectCoverPicture'))}
        label={`${t('layout:sizeMax', {
          size: '10 Mo',
        })}, ${t('layout:sizeImage')} / ${t('layout:recommendedSize', {
          size: '1200 x 600 px',
        })}`}
        fileSizeError={t('error:fileSizeError')}
        fileTypeError={t('error:fileTypeError')}
        onChange={handleChangePicture}
      />
    );
  }, [states, t]);

  // categories
  const handleChangeCategories = (event: any, newValue: any) => {
    if (newValue.length > 1) {
      const [lastItem] = newValue.slice(-1);
      if (typeof lastItem !== 'object') {
        const newEntry = { key: lastItem, value: lastItem };
        setStates({ ...states, category: newEntry });
      } else {
        setStates({ ...states, category: newValue[1] });
      }
    } else if (newValue.length === 1) {
      const [lastItem] = newValue.slice(-1);
      if (typeof lastItem !== 'object') {
        const newEntry = { key: lastItem, value: lastItem };
        setStates({ ...states, category: newEntry });
      } else {
        setStates({ ...states, category: newValue[0] });
      }
    } else {
      setStates({ ...states, category: null });
    }
  };

  // skills
  const handleChangeSkills = (event: any, newValue: any) => {
    if (newValue.length > 0) {
      const [lastItem] = newValue.slice(-1);
      if (typeof lastItem !== 'object') {
        const newEntry = { key: lastItem, value: lastItem };
        let skills = dictionaries.skill;
        skills = Object.assign([], skills);
        skills.push(newEntry);
        setDictionaries({ ...dictionaries, skills });
        if (states.skills) {
          setStates({ ...states, skills: [...states.skills, newEntry] });
        } else {
          setStates({ ...states, skills: [newEntry] });
        }
      } else {
        setStates({ ...states, skills: newValue });
      }
    } else {
      setStates({ ...states, skills: [] });
    }
  };

  const googleMapsHandler = (localisation: ILocalisation) => {
    setStates({ ...states, localisation })
  }

  // ACTIONS
  const create = async (method: 'save' | 'published' | 'cancel' | 'addGroup' | 'removeGroup') => {
    const newStates = states;
    for (const [key, value] of Object.entries(newStates)) {
      switch (key) {
        case 'skills':
          const newSkills: string[] = [];
          if (value) {
            for (const [, v] of Object.entries(value)) {
              // @ts-ignore
              if (v.key) {
                // @ts-ignore
                newSkills.push(v.key);
              }
            }
          }
          newStates.skills = newSkills;
          break;
        case 'category':
          // @ts-ignore
          newStates.category = value?.key;
          break;
        case 'coverPicture':
          if (states?.coverPicture?.length === 1) {
            newStates.coverPicture = await encodeImageFileAsURL(value[0]);
          }
          break;
        case 'addGroup':
          newStates.hasDiscussionGroup = true;
          break;
        case 'removeGroup':
          newStates.hasDiscussionGroup = false;
          break;
        case 'maxAttendees':
          if (states.totalMembers < states.maxAttendees) {
            newStates.isFull = false;
          } else if (states.totalMembers >= states.maxAttendees) {
            newStates.isFull = true;
          }
          break;
        default:
          break;
      }
    }

    const newHoldStates = holdStates;
    if (newHoldStates) {
      for (const [key, value] of Object.entries(newHoldStates)) {
        switch (key) {
          case 'skills':
            const newSkills: string[] = [];
            // @ts-ignore
            if (value) {
              for (const [, v] of Object.entries(value)) {
                // @ts-ignore
                if (v.key) {
                  // @ts-ignore
                  newSkills.push(v.key);
                }
              }
            }
            newHoldStates.skills = newSkills;
            break;
          case 'category':
            // @ts-ignore
            newHoldStates.category = value?.key;
            break;
          default:
            break;
        }
      }
    }

    const params = newHoldStates
      ? getNewObject(newStates, newHoldStates)
      : getNewObject(newStates, initialState);

    // always send localisation data
    if (newStates.localisation === null) {
      params.localisation = null;
    } else {
      params.localisation = {
        address1: newStates?.localisation?.address1,
        address2: newStates?.localisation?.address2,
        countryCode: newStates?.localisation?.countryCode,
        googleUrl: newStates?.localisation?.googleUrl,
        latitude: newStates?.localisation?.latitude,
        longitude: newStates?.localisation?.longitude,
        postalCode: newStates?.localisation?.postalCode,
        town: isArray(newStates?.localisation?.town) ? newStates?.localisation?.town[0] : newStates?.localisation?.town,
      };
    }
    if (states.nuid) {
      params.nuid = states.nuid;
    }
    params.communityNuid = selectedCommunityOrGroupNuid;

    switch (method) {
      case 'published':
        params.status = 'open';
        break;
      case 'cancel':
        params.status = 'cancelled';
        break;
      default:
        if (
          newStates.status !== 'open' &&
          newStates.status !== 'ended' &&
          newStates.status !== 'cancelled'
        ) {
          params.status = 'draft';
        }
        break;
    }

    delete params.createdDate;
    delete params.isMember;

    if (selectedEvent) {
      await updateEvent(params as UpdateEventParams);
    } else {
      await addEvent(params as AddEventParams);
      onClickBackButton();
    }
  };

  const removeEvent = async () => {
    if (isEdit) {
      setEvent(null);
      if (states.nuid) {
        await deleteEvent(states.nuid);
      }
    } else {
      onClickBackButton();
    }
  };

  const createChannel = async () => {
    setPageIsLoading(true);
    const result = await createChannelApi({containerNuid: selectedEvent.nuid});
    if (result.fail) {
      setError(result.fail);
    } else {
      setMessage(t('message:groupChannelCreated'));
      await create('addGroup');
    }
    setPageIsLoading(false);
  }

  const deleteChannel = async () => {
    setPageIsLoading(true);
    const result = await deleteChannelApi({containerNuid: selectedEvent.nuid});
    if (result.fail) {
      setError(result.fail);
    } else {
      setMessage(t('message:groupChannelDeleted'))
      await create('removeGroup');
    }
    setPageIsLoading(false);
  }

  // EFFECTS
  React.useEffect(() => {
    if (selectedEvent !== null && !pageIsLoading) {
      setStates({ ...selectedEvent });
      setHoldStates({ ...selectedEvent });
      if (selectedEvent.coverPicture) {
        setCoverPicture(selectedEvent.coverPicture);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedEvent]);

  return (
    <div className={CLASSNAME}>
      <div className={`${CLASSNAME}_topContainer`}>
        <div>
          {!isEdit && (
            <IconButton
              onClick={() => {
                setModalAction('back');
                setIsConfirmationOpen(true);
              }}
            >
              <ArrowBack />
            </IconButton>
          )}
          <h1>{isEdit ? capitalize(t('event:editEvent')) : capitalize(t('event:createEvent'))}</h1>
          {' - '}
          <p>{t('event:requiredFields')}</p>
        </div>
        <div className={`${CLASSNAME}_topContainer_actions`}>
          {(states.status === 'draft' ||
            states.status === 'ended' ||
            states.status === 'cancelled') &&
            canDelete && (
              <DangerButton
                label={capitalize(t('words:delete'))}
                onClick={() => {
                  setModalAction('delete');
                  setIsConfirmationOpen(true);
                }}
              />
            )}
          {(states.status === 'open') && (
            <DangerButton
              label={capitalize(t('event:cancelEvent'))}
              onClick={() => {
                setModalAction('cancel');
                setIsConfirmationOpen(true);
              }}
            />
          )}
          {states.status !== 'canceled' && states.status !== 'ended' && (
            <SuccessButton
              outlined
              label={capitalize(t('words:save'))}
              onClick={() => create('save')}
              disabled={states.status === 'open' ? isDisabled() : isError}
            />
          )}
          {states.status === 'draft' && (
            <SuccessButton
              label={capitalize(t('words:publish'))}
              onClick={() => create('published')}
              disabled={isDisabled()}
            />
          )}
          {states.status !== 'draft' && states.status !== 'cancelled' &&
          selectedCommunity?.chatApplicationId && !states.hasDiscussionGroup &&
          (
            <SuccessButton
              label={t('layout:createChannel')}
              onClick={() => createChannel()}
            />
          )}
          {states.status !== 'draft' && states.status !== 'cancelled' &&
          selectedCommunity?.chatApplicationId && states.hasDiscussionGroup &&
          (
            <DangerButton
              label={t('layout:deleteChannel')}
              onClick={() => setIsChannelDeleteConfirmationOpen(true)}
            />
          )}

        </div>
      </div>
      <div className={`${CLASSNAME}_container`}>
        <div className={`${CLASSNAME}_container_status`}>
          <div>
            <h3>{capitalize(t('event:totalMembers'))} :</h3>
            <p>{states.totalMembers}</p>
          </div>
          <div>
            <h3>{capitalize(t('event:status'))} :</h3>
            <span>
              {capitalize(t(`event:${states.status}`))}
              {states.isFull && `-${t('event:full')}`}
            </span>

          </div>
        </div>
        <div className={`${CLASSNAME}_container_top`}>
          <div className={`${CLASSNAME}_container_top_accessType`}>
            <SelectField
              isRequired
              id="accessType"
              label={capitalize(t('event:accessType'))}
              data={accessTypeArray.map((e) => {
                return { key: e.key, value: capitalize(t(`words:${e.value}`)) };
              })}
              value={states.accessType || ''}
              onSelect={onSelectAccessType}
            />
          </div>
          <div className={`${CLASSNAME}_container_top_category`}>
            <Autocomplete
              autoSelect
              blurOnSelect
              color={'secondary'}
              size="small"
              filterSelectedOptions
              freeSolo
              multiple
              id="category"
              options={eventState.eventCategories || []}
              getOptionLabel={(option: any) => option.value}
              value={states.category ? [states.category] : []}
              onChange={handleChangeCategories}
              renderInput={(params) => (
                <TextField
                  variant="outlined"
                  {...params}
                  required
                  label={capitalize(t('event:category'))}
                />
              )}
            />
          </div>
          {states.status === 'draft' && (
            <div className={`${CLASSNAME}_switch`}>
              <p>{capitalize(t('event:promote'))}</p>
              <Switch
                id={'promote'}
                checked={states.promote ?? false}
                color="primary"
                onChange={onSwitch}
                disabled={states?.accessType === 'private' || states?.accessType === 'private_hidden'}
              />
              <HelpIcon
                className={`${CLASSNAME}_switch_helper`}
                color="primary"
                onClick={() => setIsHelpOpen(true)}
              />
            </div>
          )}
        </div>
        <div className={`${CLASSNAME}_container_mid`}>
          <TextField
            size="small"
            variant="outlined"
            required
            id="name"
            type="text"
            label={capitalize(t('event:title'))}
            value={states.name || ''}
            onChange={onChangeText}
          />
          <div className={`${CLASSNAME}_container_date`}>
            <TextField
              size="small"
              variant="outlined"
              required
              id="startDate"
              label={capitalize(t('event:startDate'))}
              type="datetime-local"
              value={states.startDate || ''}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={onChangeText}
              error={errors.isStartDateError}
              helperText={errors.isStartDateError && capitalize(t('event:dateStartError'))}
            />
            <TextField
              size="small"
              variant="outlined"
              required
              id="endDate"
              label={capitalize(t('event:endDate'))}
              type="datetime-local"
              value={states.endDate || ''}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={onChangeText}
              error={errors.isEndDateError}
              helperText={errors.isEndDateError && capitalize(t('event:dateEndError'))}
            />
          </div>
          <div className={`${CLASSNAME}_banner`}>
            <p className={`${CLASSNAME}_miniTitle`}>{capitalize(t('event:banner'))}* :</p>
            <div className={`${CLASSNAME}_banner_preview`}>
              <img src={coverPicture || DEFAULT_BANNER} alt={t('event:banner')} />
            </div>
            <div className={`${CLASSNAME}_banner_uploader`}>{uploader}</div>
          </div>
          <GoogleMaps searchTitle={t('event:whereIsEvent')} localisation={states.localisation} updateHandler={googleMapsHandler} />
          <TextField
            size="small"
            variant="outlined"
            required
            multiline
            minRows={3}
            id="description"
            type="text"
            label={capitalize(t('event:description'))}
            value={states.description || ''}
            onChange={onChangeText}
          />
          <AutocompleteLab
            size="small"
            filterSelectedOptions
            freeSolo
            multiple
            id="skills"
            options={dictionaries?.skill || []}
            getOptionLabel={(option: any) => option.value}
            getOptionSelected={(option: any, value: any) => option.key === value.key}
            value={states.skills || []}
            onChange={handleChangeSkills}
            renderInput={(defaultParams: AutocompleteRenderInputParams) => (
              <TextField
                {...defaultParams}
                required
                variant="outlined"
                label={t('layout:skillAndFocus')}
              />
            )}
          />
          <div className={`${CLASSNAME}_numerics`}>
            <div className={`${CLASSNAME}_numerics_information`}>
              <TextField
                size="small"
                variant="outlined"
                required
                id="maxAttendees"
                type="number"
                label={capitalize(t('event:maxAttendees'))}
                value={states?.maxAttendees?.toString() || ''}
                error={errors.isMaxAttendeesError}
                onChange={onChangeNumber}
              />
              <div className={`${CLASSNAME}_numerics_price`}>
                <TextField
                  size="small"
                  variant="outlined"
                  required
                  id="price"
                  type="number"
                  label={capitalize(t('event:price'))}
                  value={states?.price?.toString() || ''}
                  onChange={onChangeNumber}
                  color="primary"
                />
                <Select
                  id={'currency'}
                  label={capitalize(t('words:currency'))}
                  value={states.currency || 'euro'}
                  onChange={handleChangeCurrency}
                >
                  <MenuItem value={'euro'}>€</MenuItem>
                  <MenuItem value={'dollar'}>$</MenuItem>
                </Select>
              </div>
            </div>
            <div className={`${CLASSNAME}_switch`}>
              <p>{capitalize(t('event:variablePrice'))}</p>
              <Switch
                id="variablePrice"
                checked={states.variablePrice ?? false}
                color="primary"
                onChange={onSwitch}
              />
            </div>
          </div>
          {errors.isMaxAttendeesError && (
            <p className={`${CLASSNAME}_errorText`}>{capitalize(t('event:maxAttendeesError'))}</p>
          )}
          {errors.isUrlEventError && (
            <p className={`${CLASSNAME}_errorText`}>{capitalize(t('event:urlError'))}</p>
          )}
          <TextField
            size="small"
            variant="outlined"
            id="urlTicketing"
            type="url"
            label={capitalize(t('event:urlTicketing'))}
            value={states.urlTicketing || ''}
            onChange={onChangeText}
            placeholder="www.mywebsite.com"
            error={!!errors.isUrlTicketingError}
          />
          {errors.isUrlTicketingError && (
            <p className={`${CLASSNAME}_errorText`}>{capitalize(t('event:urlError'))}</p>
          )}
          <TextField
            color={'primary'}
            size="small"
            variant="outlined"
            id="urlVisio"
            type="url"
            label={capitalize(t('event:urlVisio'))}
            value={states.urlVisio || ''}
            onChange={onChangeText}
            placeholder="www.mywebsite.com"
            error={!!errors.isUrlVisioError}
          />
          {errors.isUrlVisioError && (
            <p className={`${CLASSNAME}_errorText`}>{capitalize(t('event:urlError'))}</p>
          )}
          <TextField
            color={'primary'}
            size="small"
            variant="outlined"
            id="urlEvent"
            type="url"
            label={capitalize(t('event:urlEvent'))}
            value={states.urlEvent || ''}
            onChange={onChangeText}
            placeholder="www.mywebsite.com"
            error={!!errors.isUrlEventError}
          />
        </div>
      </div>
      <YesNoModal
        size={Sizes.md}
        yesAction={() => setIsHelpOpen(false)}
        yesLabel={t('words:close')}
        text={
          <div>
            <p>{t('event:helpForPromote_part1')}</p>
            <p>{t('event:helpForPromote_part2')}</p>
            <p>{t('event:helpForPromote_part3')}</p>
            <p>{t('event:helpForPromote_part4')}</p>
          </div>
        }
        title={capitalize(t('event:helperTitle'))}
        open={isHelpOpen}
      />
      <YesNoModal
        size={Sizes.md}
        yesAction={async () => {
          setIsConfirmationOpen(false);
          switch (modalAction) {
            case 'back':
              onClickBackButton();
              break;
            case 'cancel':
              await create('cancel');
              break;
            case 'delete':
              await removeEvent();
              break;
            default:
              break;
          }
        }}
        yesLabel={t('words:yes')}
        noAction={() => setIsConfirmationOpen(false)}
        noLabel={t('words:no')}
        text={
          <p>
            {capitalize(
              t(
                `event:${
                  (modalAction === 'back' && 'confirmBack') ||
                  (modalAction === 'cancel' && 'confirmCancel') ||
                  (modalAction === 'delete' && 'confirmRemove')
                }`
              )
            )}
          </p>
        }
        title={capitalize(t('layout:deleteConfirmation'))}
        open={isConfirmationOpen}
      />
      <YesNoModal
        size={Sizes.md}
        yesAction={async () => {
          setIsChannelDeleteConfirmationOpen(false);
          await deleteChannel();
        }}
        yesLabel={t('words:yes')}
        noAction={() => setIsChannelDeleteConfirmationOpen(false)}
        noLabel={t('words:no')}
        text={<p>{capitalize(t('layout:confirmGroupChannelDeletion'))}</p>}
        title={capitalize(t('layout:deleteConfirmation'))}
        open={isChannelDeleteConfirmationOpen}
      />
    </div>
  );
};

EventForm.displayName = 'EventForm';
export default EventForm;
