import { sessionContext } from '../../../_hook/useSession';
import logo from '../../../assets/images/Logo_SuperConnectr.png';
import ActionButton from '../../../components/generic/ActionButton';
import {
  CREATE_COMMUNITY_URL,
  PRIVACY_POLICY_URL,
  TERMS_OF_SERVICE_URL,
} from '../../../constants/urls.constants';
import WebStorageManagement from '../../../helpers/WebStorageManager';
import './index.scss';
import {
  Button,
  CircularProgress,
  FormControl,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  makeStyles,
  MenuItem,
  Paper,
  Select,
} from '@material-ui/core';
import { ArrowBack, Visibility, VisibilityOff } from '@material-ui/icons';
import { isEmpty } from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

const CLASSNAME = 'signIn';

const SignIn: React.FC = () => {
  const { t } = useTranslation(['connect', 'words', 'register']);
  const navigate = useNavigate();

  const { sessionState, setEmail, setPassword, login, choseCommunity, setError } =
    React.useContext(sessionContext);
  const { email, password } = sessionState;

  const [showPassword, setShowPassword] = React.useState(false);
  // @ts-ignore
  const [isEmailValid, setIsEmailValid] = React.useState(false);
  const [waiting, setWaiting] = React.useState(false);
  const [step, setStep] = React.useState(0);
  const [communityToConnect, setCommunityToConnect] = React.useState(null);
  const [clCommunities, setClCommunities] = React.useState<any>(null);

  const handleTextChange = (event: any) => {
    switch (event.target.name) {
      case 'email':
        setEmail(event.target.value);
        setIsEmailValid(event.target.value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i) !== null);
        break;
      case 'password':
        setPassword(event.target.value);
        break;
      default:
        break;
    }
  };

  const handleChoseCommunity = async () => {
    setWaiting(true);
    try {
      await choseCommunity(email).then((response: any) => {
        setClCommunities(response?.content);
        if (!isEmpty(response?.content)) {
          setStep(step + 1);
        } else {
          setStep(step + 2);
        }
        setWaiting(false);
      });
    } catch (error: any) {
      if (error?.response?.data) {
        const { fail } = error.response.data;
        setError(`${fail}`);
      } else {
        setError(t('connect:unknownError'));
      }
      setWaiting(false);
    }
  };

  const handleChange = (event: any) => {
    setCommunityToConnect(event.target.value);
    if (step < 2) {
      setStep(step + 1);
    }
  };

  const handleKeyPress = (event: any) => {
    if ((event.key === 'Enter' || event.keyCode === 13) && isEmailValid) {
      setWaiting(true);
      if (step === 0) {
        handleChoseCommunity().then();
      }
      if (step === 2) {
        login().then(() => {
          setWaiting(false);
        });
      }
      setWaiting(false);
    }
  };

  const handleBackAction = () => {
    if (step === 2) {
      setCommunityToConnect(null);
      setStep(step - 2);
    } else {
      setStep(step - 1);
    }
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event: any) => {
    event.preventDefault();
  };

  const onClickExternalLink = (url: string) => {
    // eslint-disable-next-line no-unused-expressions
    window.open(url, '_blank')?.focus();
  };

  const changePassword = () => {
    navigate('/forgotten', {
      state: {
        email,
      },
    });
  };

  const handleConnect = async () => {
    setWaiting(true);
    try {
      await login();
    } catch (error: any) {
      if (error?.response?.data) {
        const { fail } = error.response.data;
        setError(`${fail}`);
      } else {
        setError(t('connect:unknownError'));
      }
    }
    setWaiting(false);
  };

  const useStyles = makeStyles((theme) => ({
    root: {
      padding: theme.spacing(5),
    },
    margin: {
      marginTop: theme.spacing(0),
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
  }));
  const classes = useStyles();
  // @ts-ignore
  document.getElementsByTagName('body')[0].style = 'background-color: #F29C49';

  React.useEffect(() => {
    WebStorageManagement.setValue('selectedCommunity', {
      whiteLabel: communityToConnect !== 'superconnectr' ? 'yes' : 'no',
      whiteLabelName: communityToConnect,
    });
  }, [communityToConnect]);

  React.useEffect(() => {
    setIsEmailValid(email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i) !== null);
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, []);

  // @ts-ignore
  return (
    <div className={CLASSNAME}>
      <Paper className={`${CLASSNAME}_root ${classes.root}`} elevation={5}>
        <div>
          <img style={{ width: '90%' }} src={logo} alt="logo SuperConnectr" />
          <div className={`${CLASSNAME}_header`}>
            {step !== 0 && (
              <div className={`${CLASSNAME}_btnBack`}>
                <Button variant="text" onClick={() => handleBackAction()}>
                  <ArrowBack />
                </Button>
              </div>
            )}
            <p className={`${CLASSNAME}_welcome`}>{t('words:welcome')}</p>
          </div>
          {step === 0 && (
            <FormControl fullWidth>
              <InputLabel
                htmlFor="email-label"
                className={`${CLASSNAME}_margin ${classes.margin}`}
                style={{ fontWeight: 600 }}
              >
                {t('words:email')}
              </InputLabel>
              <Input
                className={`${CLASSNAME}_margin ${classes.margin}`}
                error={email !== '' && !isEmailValid}
                id="email"
                onKeyPress={handleKeyPress}
                value={email}
                onChange={handleTextChange}
                name="email"
              />
            </FormControl>
          )}
          {step >= 1 && (
            <FormControl fullWidth>
              <InputLabel>{t('connect:selectAnApplication')}</InputLabel>
              <Select
                value={communityToConnect || ''}
                label={t('connect:selectACommunity')}
                onChange={handleChange}
              >
                {clCommunities?.map((item: any) => (
                  <MenuItem key={item.whiteLabelName} value={item.whiteLabelName}>
                    {item?.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
          {step >= 2 && (
            <>
              <FormControl fullWidth style={{ marginTop: 20 }}>
                <InputLabel
                  htmlFor="password-label"
                  className={`${CLASSNAME}_margin ${classes.margin}`}
                  style={{ fontWeight: 600 }}
                >
                  {t('words:password')}
                </InputLabel>
                <Input
                  className={`${CLASSNAME}_margin ${classes.margin}`}
                  id="password"
                  onKeyPress={handleKeyPress}
                  type={showPassword ? 'text' : 'password'}
                  value={password}
                  onChange={handleTextChange}
                  name="password"
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>
              <Button
                style={{ marginTop: 16, fontWeight: 600 }}
                size="small"
                variant="text"
                onClick={changePassword}
              >
                {t('forgottenPassword')}
              </Button>
            </>
          )}
          <br />
          <br />
          {waiting && <CircularProgress />}
          {!waiting && !step && (
            <ActionButton
              bold
              size="large"
              disabled={!isEmailValid}
              onClick={handleChoseCommunity}
              label={t('words:next')}
            />
          )}
          {!waiting && step === 2 && (
            <ActionButton
              bold
              size="large"
              // @ts-ignore
              onKeyPress={handleKeyPress}
              disabled={!isEmailValid}
              onClick={handleConnect}
              label={t('words:connection')}
            />
          )}
          <p>
            <ActionButton
              bold
              size="small"
              label={t('createCommunity')}
              onClick={() => onClickExternalLink(CREATE_COMMUNITY_URL)}
            />
          </p>
        </div>
        <div>
          <Button
            style={{ fontWeight: 400, fontSize: 10, minWidth: 'auto' }}
            size="small"
            variant="text"
            onClick={() => onClickExternalLink(TERMS_OF_SERVICE_URL)}
          >
            {t('register:cguAccept2')}
          </Button>
        </div>
        <div>
          <Button
            style={{ fontWeight: 400, fontSize: 10, minWidth: 'auto' }}
            size="small"
            variant="text"
            onClick={() => onClickExternalLink(PRIVACY_POLICY_URL)}
          >
            {t('register:privacyPolicy')}
          </Button>
        </div>
      </Paper>
    </div>
  );
};

SignIn.displayName = 'SignIn';
export default SignIn;
