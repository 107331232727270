import { ActualityAdmin, ActualityCommunity } from './actualities/actualities';
import Community from './community';
import EventPage from './events';
import GroupsPage from './groups';
import HomePage from './home';
import MembersPage from './members';
import {
  Chat,
  Edit,
  Event,
  Group,
  Home,
  LocalLibrary,
  SupervisedUserCircle,
  ShareOutlined
} from '@material-ui/icons';
import RecoPage from "./reco";
import React from "react";
import { sessionContext } from "../_hook/useSession";

export enum RouteSelection {
  EVENT_SELECTED = 'event',
  GROUP_SELECTED = 'group',
  COMMUNITY_SELECTED = 'community',
}

const routes = (selection: RouteSelection, isPro: boolean, isSendbirdCommunity: boolean) => {
  const isEventSelected = selection === RouteSelection.EVENT_SELECTED;
  const isGroupSelected = selection === RouteSelection.GROUP_SELECTED;

  return [
    {
      component: HomePage,
      icon: Home,
      id: 0,
      name: 'home',
      show: true,
      url: '/',
    },
    {
      component: MembersPage,
      icon: Group,
      id: 1,
      name: isEventSelected ? 'participants' : 'members',
      show: true,
      url: '/members',
    },
    {
      component: ActualityAdmin,
      icon: LocalLibrary,
      id: 2,
      name: isEventSelected ? 'live' : 'actualities',
      show: true,
      url: '/news',
    },
    {
      component: ActualityCommunity,
      icon: Chat,
      id: 3,
      name: 'discussions',
      show: true,
      url: '/chat',
    },
    {
      component: EventPage,
      icon: isEventSelected ? Edit : Event,
      id: 4,
      name: isEventSelected ? 'edit' : 'event',
      show: isPro,
      url: '/event',
    },
    {
      component: GroupsPage,
      icon: isGroupSelected ? Edit : SupervisedUserCircle,
      id: 5,
      name: isGroupSelected ? 'edit' : 'groups',
      show: isPro && selection !== RouteSelection.EVENT_SELECTED,
      url: '/groups',
    },
    {
      component: RecoPage,
      icon: ShareOutlined,
      id: 6,
      name: 'recommandation',
      show: isPro && !isEventSelected && isSendbirdCommunity,
      url: '/reco',
    },
    {
      component: isGroupSelected ? GroupsPage : Community,
      icon: Edit,
      id: 7,
      name: 'edit',
      show:
        selection !== RouteSelection.GROUP_SELECTED && selection !== RouteSelection.EVENT_SELECTED,
      url: '/community',
    },
  ];
};

export default routes;
