import useEvent from '../../_hook/useEvent';
import { sessionContext } from '../../_hook/useSession';
import ICON_EVENT from '../../assets/images/icon_event.png';
import EmptyState from '../../components/EmptyState';
import EventForm from '../../components/form/EventForm';
import { ToolBarItemButton } from '../../components/generic/buttons/ToolBarItemsButton';
import ScPaginator from '../../components/generic/paginator';
import ToolBarSearchField from '../../components/generic/ToolBarSearchField';
import Table from '../../components/table';
import loadDataInTable from '../../components/table/loadDataInTable';
import TagCommunities from '../../components/user/TagCommunities';
import { DIMENSION_PHONE } from '../../constants/dimensions.constants';
import getIsPro from '../../constants/getIsPro';
import useWindowDimensions from '../../helpers/getWindowDimensions';
import './index.scss';
import { GridColDef, GridSortModel } from '@mui/x-data-grid';
import { GridSortDirection } from '@mui/x-data-grid/models/gridSortModel';
import capitalize from 'lodash/capitalize';
import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { FaCheck } from "react-icons/fa";
import { CHECKED_COLOR } from "../../constants/colors.constants";

const CLASSNAME = 'event';

const EventPage: React.FC = () => {
  const { t } = useTranslation(['layout, words']);
  const navigate = useNavigate();

  // responsive
  const { width } = useWindowDimensions();

  // useSession
  const { sessionState, setPageIsLoading, setEvent, setGroup } = React.useContext(sessionContext);
  const { selectedCommunity, selectedGroup, selectedEvent, pageIsLoading } = sessionState;
  const selectedCommunityOrGroupNuid = selectedGroup?.nuid || selectedCommunity?.nuid;

  // useEvent
  const { eventState, getEvents, loadEventsPageEventData, setEventsParams } = useEvent();
  const { eventsInformation, eventsParams } = eventState;

  // STATES
  const [columns, setColumns] = React.useState<GridColDef[]>([]);
  const [rows, setRows] = React.useState([]);
  const [elementsPerPage, setElementsPerPage] = React.useState<number>(eventsParams.maxPerPage);
  const [isCreation, setIsCreation] = React.useState(false);

  const conditions = (columnName: string, clmns: GridColDef[]) => {
    if (!clmns.find((c) => c.field === columnName)) {
      switch (columnName) {
        case 'name':
          clmns.push({
            field: columnName,
            headerName: 'event_' + columnName,
            width: 400,
            // eslint-disable-next-line react/jsx-props-no-spreading
            renderCell: (params) => (
              <p className="pInTable">
                {params.value}
              </p>
            ),
          });
          break;        case 'coverPicture':
          clmns.push({
            field: columnName,
            headerName: '',
            width: 75,
            renderCell: (params) => <TagCommunities items={[params?.row] || []} entity={'event'} />,
          });
          break;
        case 'startDate':
          clmns.push({
            field: columnName,
            headerName: 'event_' + columnName,
            // eslint-disable-next-line react/jsx-props-no-spreading
            renderCell: (params) => (
              <p className="pInTable">
                <span>{params?.value && capitalize(moment(params?.value).format('llll'))}</span>
              </p>
            ),
          });
          break;
        case 'endDate':
          clmns.push({
            field: columnName,
            headerName: 'event_' + columnName,
            // eslint-disable-next-line react/jsx-props-no-spreading
            renderCell: (params) => (
              <p className="pInTable">
                <span>{params?.value && capitalize(moment(params?.value).format('llll'))}</span>
              </p>
            ),
          });
          break;
        case 'createdDate':
          clmns.push({
            field: columnName,
            headerName: 'event_' + columnName,
            // eslint-disable-next-line react/jsx-props-no-spreading
            renderCell: (params) => (
              <p className="pInTable">
                {params?.value && capitalize(moment(params?.value).format('LL'))}
              </p>
            ),
          });
          break;
        case 'status':
          clmns.push({
            field: columnName,
            headerName: 'event_' + columnName,
            width: 100,
            // eslint-disable-next-line react/jsx-props-no-spreading
            renderCell: (params) => (
              <p className="pInTable">
                {capitalize(t(`event:${params.value}`))}
              </p>
            ),
          });
          break;
        case 'isFull':
          clmns.push({
            field: columnName,
            headerName: 'event_' + columnName,
            width: 100,
            // eslint-disable-next-line react/jsx-props-no-spreading
            renderCell: (params) => (
              <p className="pInTable">
                {params.value && <FaCheck color={CHECKED_COLOR} size={16} />}
              </p>
            ),
          });
          break;
        case 'accessType':
          clmns.push({
            field: columnName,
            headerName: 'event_' + columnName,
            // eslint-disable-next-line react/jsx-props-no-spreading
            renderCell: (params) => (
              <p className="pInTable">{capitalize(t(`words:accessType_${params.value}`))}</p>
            ),
          });
          break;
        case 'community':
          clmns.push({
            field: columnName,
            headerName: '',
            width: 75,
            // eslint-disable-next-line react/jsx-props-no-spreading
            renderCell: (params) => (
              <div className="scrollableContentInTable">
                <TagCommunities items={[params?.value]} />
              </div>
            ),
          });
          break;
        default:
          break;
      }
    }
  };
  // GroupBy
  const [sortModel, setSortModel] = React.useState<{ field: string; sort: GridSortDirection }[]>([
    {
      field: 'createdDate',
      sort: 'desc',
    },
  ]);
  // const sortableColumns = ['name', 'startDate', 'endDate', 'status', 'accessType', 'createdDate'];

  // ACTIONS
  const handleSearch = async (searchValue: string) => {
    await getEvents({
      communityNuid: selectedCommunityOrGroupNuid,
      searchString: searchValue,
      page: 1,
      maxPerPage: eventState.eventsParams.maxPerPage,
      desc: eventState.eventsParams.desc,
      orderBy: eventState.eventsParams.orderBy,
      addGroupEvents: selectedCommunity && !selectedGroup,
    });
  };

  const handleChangePage = async (newPage: number) => {
    await getEvents({
      communityNuid: selectedCommunityOrGroupNuid,
      page: newPage,
      maxPerPage: eventState.eventsParams.maxPerPage,
      searchString: eventState.eventsParams.search,
      desc: eventState.eventsParams.desc,
      orderBy: eventState.eventsParams.orderBy,
      addGroupEvents: selectedCommunity && !selectedGroup,
    });
  };

  const handleChangeItemsPerPage = async (newItemsPerPage: number) => {
    setElementsPerPage(newItemsPerPage);
    await getEvents({
      communityNuid: selectedCommunityOrGroupNuid,
      page: 1,
      maxPerPage: newItemsPerPage,
      desc: eventState.eventsParams.desc,
      orderBy: eventState.eventsParams.orderBy,
      addGroupEvents: selectedCommunity && !selectedGroup,
    });
  };

  const handleSelection = (selection: number) => {
    const item = eventsInformation.events[selection];
    if (item) {
      const eventCommunity = item.community;
      if (item?.nuid !== selectedEvent?.nuid || !selectedGroup) {
        setEvent(item);
        if (eventCommunity.type === 'group') {
          setGroup(eventCommunity);
        }
        navigate('/');
      }
    }
  };

  const handleSortModelChange = async (model: GridSortModel) => {
    setSortModel(model);
    await getEvents({
      communityNuid: selectedCommunityOrGroupNuid,
      searchString: eventState.eventsParams.search,
      page: 1,
      maxPerPage: eventState.eventsParams.maxPerPage,
      desc: model[0]?.sort === 'desc',
      orderBy: model[0]?.field,
      addGroupEvents: selectedCommunity && !selectedGroup
    });
  };

  const handleRemoveUser = async () => {

  }

  // EFFECTS
  // Change all states when changing community or group
  React.useEffect(() => {
    setPageIsLoading(true);
    if (selectedCommunityOrGroupNuid) {
      setEventsParams({
        page: 1,
        maxPerPage: 50,
        search: '',
        desc: true,
        orderBy: null,
        addGroupEvents: selectedCommunity && !selectedGroup,
      });
      if (selectedEvent) {
        (async () => {
          await loadEventsPageEventData(null, selectedEvent.nuid).then(() =>
            setPageIsLoading(false)
          );
        })();
      }
      if (!isCreation && !selectedEvent) {
        (async () => {
          await loadEventsPageEventData(selectedCommunityOrGroupNuid).then(() =>
            setPageIsLoading(false)
          );
        })();
      }
      if (isCreation) {
        setPageIsLoading(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCreation, selectedCommunityOrGroupNuid, selectedEvent]);

  // Create columns and load rows
  React.useEffect(() => {
    const dataColumns =
      width >= DIMENSION_PHONE
        ? [
            'coverPicture',
            'name',
            'startDate',
            'endDate',
            'accessType',
            'registeredParticipants',
            'status',
            'isFull',
            'totalMembers',
            'createdDate',
            'community',
          ]
        : ['coverPicture', 'name', 'status'];
    loadDataInTable(
      eventsInformation.events,
      dataColumns,
      setColumns,
      setRows,
      conditions,
      'event'
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventsInformation?.events?.length, sortModel]);

  React.useEffect(() => {
    setElementsPerPage(eventsParams.maxPerPage);
  }, [eventsParams.maxPerPage]);

  if (getIsPro(selectedCommunity)) {
    return (
      <>
        {!pageIsLoading && (
          <div className={CLASSNAME}>
            {!selectedEvent && !isCreation ? (
              <>
                <h1>{capitalize(t('words:events'))}</h1>
                <div className={`${CLASSNAME}_topBar`}>
                  {width >= DIMENSION_PHONE && (
                    <ToolBarSearchField
                      onSearch={handleSearch}
                      value={eventsParams.search}
                      label={'eventSearch'}
                    />
                  )}
                  <ToolBarItemButton
                    label={capitalize(t('layout:createEvent'))}
                    action={() => setIsCreation(true)}
                  />
                </div>
                <div className={`${CLASSNAME}_table`}>
                  <Table
                    checkboxSelection={false}
                    columns={columns}
                    disableSelectionOnClick={false}
                    noRowsMessage={
                      <EmptyState
                        image={<img src={ICON_EVENT} alt={'icon_event'} />}
                        message={t('event:noEventYet')}
                      />
                    }
                    onPageChange={handleChangePage}
                    onPageSizeChange={handleChangeItemsPerPage}
                    onSelectionModelChange={handleSelection}
                    onSortModelChange={(newSortModel) => handleSortModelChange(newSortModel)}
                    page={eventsParams.page - 1}
                    pageSize={elementsPerPage}
                    rowCount={eventsInformation.total}
                    rows={rows}
                    sortModel={sortModel}
                    // sortableColumns={sortableColumns}
                  />
                </div>
                <ScPaginator
                  totalItems={eventsInformation.total}
                  returnChangePage={handleChangePage}
                  returnChangeRowsPerPage={handleChangeItemsPerPage}
                  page={eventsParams.page}
                  rowsPerPage={elementsPerPage}
                />
              </>
            ) : (
              <div className={`${CLASSNAME}_form`}>
                <EventForm
                  eventState={eventState}
                  isEdit={!isCreation}
                  onClickBackButton={() => setIsCreation(false)}
                  pageIsLoading={pageIsLoading}
                />
              </div>
            )}
          </div>
        )}
      </>
    );
  }

  return null;
};

EventPage.displayName = 'EventPage';
export default EventPage;
